import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import { useForm } from 'react-hook-form'
import TextField from '../components/TextField'
import Button from '../components/Button'
import Checkbox from '../components/Checkbox'
import bgLogin from '../assets/images/bgLogin.jpg'
import logo from '../assets/images/logo.svg'
import PasswordField from '../components/PasswordField'
import Link from '../components/Link'
import {
    useMutation, useQuery,
} from '@tanstack/react-query'
import { useStoreActions } from 'easy-peasy'
import {
    useNavigate, useParams
} from "react-router-dom";
import Toast from "../components/Toast";
import userService from '../services/userService'


const Login = () => {
    const [letUserMe, setLetUserMe] = useState(false)
    const [isToast, setIsToast] = useState(false)
    const loginUser = useStoreActions((actions) => actions.auth.loginUser)
    const setChairLevelSlug = useStoreActions((actions) => actions.user.setChairLevelSlug)
    const { status } = useParams();


    useEffect(() => {
        status === 'expired' && setIsToast(true)
    }, [status])

    useEffect(() => {
        isToast && Toast({
            type: 'warning',
            title: 'Twoja sesja wygasła. Zaloguj się ponownie.',
        })
    }, [isToast])


    const navigate = useNavigate()

    const { mutate: mutateLogin } = useMutation(
        loginUser,
        {
            onSuccess: () => {
                setLetUserMe(true)
                Toast({
                    type: 'success',
                    title: 'Logowanie przebiegło prawidłowo',
                });
            },
            onError: () => {
                Toast({
                    type: 'error',
                    title: 'Wprowadzono nieprawidłowy login lub hasło.',
                    description: 'Poprawny login to Twój firmowy adres e-mail. Sprawdź, czy podane hasło zgadza się z tym, które otrzymałeś w wiadomości e-mail i spróbuj ponownie.'
                });
            }
        }
    )

    // eslint-disable-next-line
    const userMeQuery = useQuery(
        ['userMe'],
        userService.userMe(),
        {
            enabled: letUserMe,
            onSuccess: (data) => {
                setChairLevelSlug(data.universityChairLevel.slug)
                navigate('/')
            }
        }
    )

    const { register, handleSubmit, } = useForm()

    const onSubmit = (data) => {
        mutateLogin(data)
    }

    return (
        <LoginStyled>
            <StyledContainer>
                <FormWrapper>
                    <Logo src={logo} alt='logo' />
                    <Title>Witamy!</Title>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            placeholder='Adres e-mail'
                            {...register('email')}
                        />
                        <PasswordField
                            placeholder='Hasło'
                            {...register('password')}
                        />
                        <Box>
                            <Checkbox {...register('remember')} />
                            <Button type='submit'>Zaloguj się</Button>
                        </Box>
                        <StyledLink>
                            Nie pamiętasz hasła?
                        </StyledLink>
                    </form>
                </FormWrapper>
                <Copy>© Berlin-Chemie Menarini {new Date().getFullYear()}</Copy>
            </StyledContainer>
        </LoginStyled>
    )
}

export default Login

const LoginStyled = styled.div`
    min-height: 100vh;
    background-image: url(${bgLogin});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`

const Logo = styled.img`
    display: block;
    margin: 0 auto 30px auto;
`

const FormWrapper = styled.div`
    width: 400px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 70px;
    @media only screen and (max-width: 424px) {
        width: 100%;
        margin-top: 20px;
    }
`

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const Title = styled.div`
    font-size: 57px;
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    font-family: 'Merriweather', serif;
    margin-bottom: 34px;
    text-align: center;
`

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    @media only screen and (max-width: 360px) {
        flex-direction: column-reverse;
        gap: 20px;
    }
`

const Copy = styled.div`
    text-align: right;
    margin-top: auto;
    @media only screen and (max-width: 360px) {
        text-align: center;
    }
`

const StyledLink = styled(Link)`
    text-align: center;
    display: none;
`

